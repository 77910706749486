<template>
	<div>
		<div class="payment-method-holder">
			<div v-show="paymentMethod != 'ticket'" class="cards-label flex flex-start">
				<span>Cartões aceitos:</span>
				<img
					src="@/assets/images/creditcard/mastercard.png"
					alt="mastercard flag"
				/>
				<img src="@/assets/images/creditcard/visa.png" alt="visa flag" />
			</div>
		</div>

		<transition enter-active-class="animated zoomIn">
			<div v-show="paymentMethod == 'creditcard'">
				<div class="card-item" :class="{ '-active': isCardFlipped }">
					<div class="card-item__side -front">
						<div
							class="card-item__focus"
							:class="{ '-active': focusElementStyle }"
							:style="focusElementStyle"
							ref="focusElement"
						></div>
						<div class="card-item__cover">
							<img
								src="@/assets/images/creditcard/creditcard.jpg"
								class="card-item__bg"
							/>
						</div>

						<div class="card-item__wrapper">
							<div class="card-item__top">
								<img
									src="@/assets/images/creditcard/chip.png"
									class="card-item__chip"
								/>
								<div class="card-item__type">
									<transition name="slide-fade-up">
										<img
											v-show="creditCardFlagImage"
											:src="creditCardFlagImage"
											alt="bandeira do cartão"
											class="card-item__typeImg"
										/>
									</transition>
								</div>
							</div>
							<label for="cardNumber" class="card-item__number" ref="cardNumber">
								<template>
									<span v-for="(n, $index) in generalCardMask" :key="$index">
										<transition name="slide-fade-up">
											<div
												class="card-item__numberItem"
												v-if="
													$index > 4 &&
													$index < 15 &&
													creditCard.number.length > $index &&
													n.trim() !== ''
												"
											>
												*
											</div>
											<div
												class="card-item__numberItem"
												:class="{ '-active': n.trim() === '' }"
												:key="$index"
												v-else-if="creditCard.number.length > $index"
											>
												{{ creditCard.number[$index] }}
											</div>
											<div
												class="card-item__numberItem"
												:class="{ '-active': n.trim() === '' }"
												v-else
												:key="$index + 1"
											>
												{{ n }}
											</div>
										</transition>
									</span>
								</template>
							</label>
							<div class="card-item__content">
								<label for="cardName" class="card-item__info" ref="cardName">
									<div class="card-item__holder">Nome impresso</div>
									<transition name="slide-fade-up">
										<div class="card-item__name" v-if="creditCard.name.length" key="1">
											<transition-group name="slide-fade-right">
												<span
													class="card-item__nameItem"
													v-for="(n, $index) in creditCard.name.replace(/\s\s+/g, ' ')"
													v-show="$index === $index"
													:key="$index + 1"
													>{{ n }}</span
												>
											</transition-group>
										</div>
										<div class="card-item__name" v-else key="2"></div>
									</transition>
								</label>
								<div class="card-item__date" ref="cardDate">
									<label for="cardMonth" class="card-item__dateTitle"> Validade </label>
									<label for="cardMonth" class="card-item__dateItem">
										<transition name="slide-fade-up">
											<span v-if="creditCard.expMonth" :key="creditCard.expMonth">{{
												creditCard.expMonth
											}}</span>
											<span v-else key="2">MM</span>
										</transition>
									</label>
									/
									<label for="cardYear" class="card-item__dateItem">
										<transition name="slide-fade-up">
											<span v-if="creditCard.expYear" :key="creditCard.expYear">{{
												String(creditCard.expYear).slice(2, 4)
											}}</span>
											<span v-else key="2">AA</span>
										</transition>
									</label>
								</div>
							</div>
						</div>
					</div>
					<div class="card-item__side -back">
						<div class="card-item__cover">
							<img
								src="@/assets/images/creditcard/creditcard.jpg"
								class="card-item__bg"
							/>
						</div>
						<div class="card-item__band"></div>
						<div class="card-item__cvv">
							<div class="card-item__cvvTitle">CVC</div>
							<div class="card-item__cvvBand">
								<span
									style="color: var(--secondary)"
									v-for="(n, $index) in creditCard.cvc"
									:key="$index"
								>
									{{ n }}
								</span>
							</div>
							<div class="card-item__type">
								<img
									v-show="creditCardFlagImage"
									:src="creditCardFlagImage"
									class="card-item__typeImg"
								/>
							</div>
						</div>
					</div>
				</div>

				<label
					>Card
					<div id="cardElement"></div>
				</label>

				<h1>{{ creditCardStep }}</h1>

				<div class="payment-steps" :class="{ active: creditCardStep === 2 }">
					<div class="item active">Dados do cartão</div>
					<div class="item" :class="{ active: creditCardStep === 2 }">
						Dados de cobrança
					</div>
				</div>

				<transition enter-active-class="animated fadeInRight">
					<div v-show="creditCardStep == 1" class="card-form__inner">
						<label for="cardNumber" class="card-input__label"
							>Número do cartão *</label
						>
						<input
							type="text"
							id="cardNumber"
							v-mask="generateCardNumberMask"
							v-model="creditCard.number"
							v-on:focus="focusInput"
							v-on:blur="blurInput"
							@input="checkCardFlag"
							data-ref="cardNumber"
							autocomplete="off"
						/>
					</div>

					<div class="card-input">
						<label for="cardName" class="card-input__label"
							>Nome impresso no cartão *</label
						>
						<input
							type="text"
							id="cardName"
							v-model="creditCard.name"
							v-on:focus="focusInput"
							v-on:blur="blurInput"
							data-ref="cardName"
							autocomplete="off"
						/>
					</div>

					<div class="card-form__row">
						<div class="card-form__col">
							<div class="card-form__group">
								<label for="cardMonth" class="card-input__label">Validade *</label>
								<select
									class="card-input select small mb-0-mobile"
									id="cardMonth"
									v-model="creditCard.expMonth"
									v-on:focus="focusInput"
									v-on:blur="blurInput"
									data-ref="cardDate"
								>
									<option value="" disabled selected>Mês</option>
									<option
										:value="n < 10 ? '0' + n : n"
										v-for="n in 12"
										:disabled="n < minCardMonth"
										:key="n"
									>
										{{ n < 10 ? "0" + n : n }}
									</option>
								</select>
								<select
									class="card-input select small mb-0-mobile"
									id="cardYear"
									v-model="creditCard.expYear"
									v-on:focus="focusInput"
									v-on:blur="blurInput"
									data-ref="cardDate"
								>
									<option value="" disabled selected>Ano</option>
									<option
										:value="$index + minCardYear"
										v-for="(n, $index) in 12"
										:key="n"
									>
										{{ $index + minCardYear }}
									</option>
								</select>
							</div>
						</div>
						<div class="card-form__col -cvc">
							<div class="card-input">
								<label for="cardCvv" class="card-input__label">CVC *</label>
								<input
									type="text"
									id="cardCvv"
									v-mask="'####'"
									maxlength="4"
									v-model="creditCard.cvc"
									v-on:focus="flipCard(true)"
									v-on:blur="flipCard(false)"
									autocomplete="off"
								/>
							</div>
						</div>
					</div>
				</transition>

				<transition enter-active-class="animated fadeInLeft">
					<UserBillingInfo
						@setUserBillingInfo="setUserBillingInfo"
						v-show="creditCardStep === 2"
					/>
				</transition>

				<div class="flex flex-between">
					<div
						v-show="creditCardStep > 1"
						class="btn border ml-0 mr-0"
						style="border-color: #999"
						@click="creditCardStep = 1"
					>
						<span style="color: #999">Voltar</span>
					</div>
					<div
						v-show="creditCardStep === 1"
						class="btn border ml-auto mr-0 mt-0"
						@click="validateCardData"
					>
						<span>Avançar</span>
					</div>
					<div
						v-show="creditCardStep === 2"
						class="btn ml-auto mr-0"
						style="background: var(--primary)"
						@click="validateUserData"
					>
						<span v-show="!validatingTransaction" style="color: #f0f0f0"
							>Concluir assinatura</span
						>
						<div v-show="validatingTransaction" class="loading white"></div>
					</div>
					<div
						v-show="creditCardStep === 2"
						class="btn ml-auto mr-0"
						style="background: var(--primary)"
						@click="createToken"
					>
						<span style="color: #f0f0f0">Gerar Token</span>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
	import http from "@/http";
	import validators from "@/util/validators";
	import creditCardType from "credit-card-type";
	import UserBillingInfo from "@/components/signup/UserBillingInfo.vue";
	export default {
		components: {
			UserBillingInfo,
		},
		props: {
			userInfo: {
				type: Object,
				default: () => {
					return {};
				},
			},
			ticketInfo: {
				type: Object,
				default: () => {
					return {};
				},
			},
			validatingTransaction: {
				type: Boolean,
				default: false,
			},
			plan: {
				type: Object,
				default: () => {
					return {
						price: 0,
					};
				},
			},
		},
		data() {
			return {
				user: {
					name: "",
					taxId: "",
					email: "",
					phoneNumber: "",
					cpf: "",
					country: "BR",
					state: "",
					postalCode: "",
					neighborhood: "",
					address: "",
					number: "",
					complement: "",
					city: "",
				},
				creditCard: {
					number: "",
					name: "",
					expMonth: "",
					expYear: "",
					cvc: "",
					flag: "",
					address_country: "BR",
				},
				creditCardStep: 1,
				showFullAddress: false,
				creditCardFlagImage: "",
				paymentMethod: "creditcard",
				minCardYear: new Date().getFullYear(),
				generalCardMask: "#### #### #### ####",
				cardNumberTemp: "",
				isCardFlipped: false,
				focusElementStyle: null,
				isInputFocused: false,
				stripe: null,
				clientSecret: "",
				elements: null,
			};
		},
		computed: {
			generateCardNumberMask() {
				return this.generalCardMask;
			},
			minCardMonth() {
				if (this.creditCard.expYear === this.minCardYear)
					return new Date().getMonth() + 1;
				return 1;
			},
		},
		created() {
			console.log("1");
			let publishableKey =
				"pk_test_51NpDhCHc5ClzK9EC8qiIkQKEpp6o5tVKEpeDRpuGIvjw8L8dUUVamDbcPktmuMDwV76Fw9XBrHZ150FJkCQX2zvd00I7EftJx7";
			this.stripe = Stripe(publishableKey);
		},
		mounted() {
			console.log("3");
		},
		methods: {
			createCardElement() {
				console.log("2");
				this.elements = this.stripe.elements({
					clientSecret: this.clientSecret,
				});

				const cardElement = this.elements.create("cardNumber");
				const testeElement = document.querySelector("#cardElement");
				console.log(testeElement);
				cardElement.mount("#cardElement");
			},
			getClientSecret() {
				http
					.get(`stripe/subscription/clientSecret?amount=${this.plan.price}`)
					.then((response) => {
						if (response.status === 200) {
							this.clientSecret = response.clientSecret;
							this.createCardElement();
						}
					})
					.catch((error) => {
						console.log(error);
					});
			},
			createToken() {
				stripe.createToken(cardElement).then(function (result) {
					console.log(result.token);
				});
			},
			setUserBillingInfo(userData) {
				this.user = { ...userData };
			},
			validateCardData() {
				if (this.creditCard.number.length < 13) {
					this.$swal({
						icon: "error",
						title: "Número do cartão *",
						text: "Por favor, verifique o número do cartão informado.",
					});
					return;
				}

				if (this.creditCard.name.length < 2) {
					this.$swal({
						icon: "error",
						title: "Nome no cartão *",
						text: "Por favor, informe o nome impresso no cartão.",
					});
					return;
				}

				if (!this.creditCard.expMonth || !this.creditCard.expYear) {
					this.$swal({
						icon: "error",
						title: "Data de validade do cartão *",
						text: "Por favor, informe a data de validade do cartão.",
					});
					return;
				}

				if (!this.creditCard.cvc) {
					this.$swal({
						icon: "error",
						title: "Código de segurança (CVC) *",
						text: "Por favor, informe o CVC do cartão.",
					});
					return;
				}

				let flags = ["mastercard", "visa"];
				if (!flags.includes(this.creditCard.flag)) {
					this.$swal({
						icon: "error",
						title: "Bandeira do cartão *",
						text:
							"Por favor, informe a bandeira do cartão. Aceitamos Mastercard e Visa.",
					});
					return;
				}
				this.creditCardStep = 2;
			},
			validateUserData() {
				if (
					this.paymentMethod === "ticket" &&
					!validators.validateCPF(this.user.taxId) &&
					!validators.validateCNPJ(this.user.taxId)
				) {
					this.$swal({
						icon: "error",
						title: "CPF/CNPJ *",
						text: "Por favor, informe um CPF ou CNPJ válido.",
					});
					return;
				}
				if (!validators.validateEmail(this.user.email)) {
					this.$swal({
						icon: "error",
						title: "E-mail *",
						text: "Por favor, informe um e-mail válido",
					});
					return;
				}
				let phoneRegex = /^[1-9]{2}9?[0-9]{8}$/;
				if (!phoneRegex.test(this.user.phoneNumber)) {
					this.$swal({
						icon: "error",
						title: "Telefone/celular *",
						text: "Por favor, informe um número válido",
					});
					return;
				}
				if (this.user.postalCode.length != 8) {
					this.$swal({
						icon: "error",
						title: "CEP *",
						text: "Por favor, informe um CEP válido.",
					});
					return;
				}
				if (!this.user.state) {
					this.$swal({
						icon: "error",
						title: "UF *",
						text: "Por favor, informe um UF válido.",
					});
					return;
				}
				if (!this.user.city) {
					this.$swal({
						icon: "error",
						title: "Cidade *",
						text: "Por favor, informe uma cidade.",
					});
					return;
				}
				if (this.user.neighborhood == "") {
					this.$swal({
						icon: "error",
						title: "Bairro *",
						text: "Por favor, informe o bairro.",
					});
					return;
				}
				if (this.user.address == "") {
					this.$swal({
						icon: "error",
						title: "Endereço *",
						text: "Por favor, informe o endereço.",
					});
					return;
				}

				const paymentMethod = this.paymentMethod === "creditcard" ? 0 : 1;
				this.$emit("setPaymentInfo", paymentMethod, this.user, this.creditCard);
			},
			prev() {
				this.$emit("prev");
			},
			setCreditCardFlagImage() {
				if (this.creditCard.flag == "mastercard") {
					this.creditCardFlagImage = require("@/assets/images/creditcard/mastercard.png");
				} else if (this.creditCard.flag == "visa") {
					this.creditCardFlagImage = require("@/assets/images/creditcard/visa.png");
				}
				// else if (this.creditCard.flag == "amex") {
				//   this.creditCardFlagImage = require("@/assets/images/creditcard/amex.jpg");
				// } else if (this.creditCard.flag == "diners") {
				//   this.creditCardFlagImage = require("@/assets/images/creditcard/diners.png");
				// } else if (this.creditCard.flag == "elo") {
				//   this.creditCardFlagImage = require("@/assets/images/creditcard/elo.png");
				// } else if (this.creditCard.flag == "hipercard") {
				//   this.creditCardFlagImage = require("@/assets/images/creditcard/hipercard.png");
				// }
				else {
					this.creditCardFlagImage = "";
				}
			},
			checkCardFlag() {
				let flag =
					creditCardType(this.creditCard.number) &&
					creditCardType(this.creditCard.number)[0]
						? creditCardType(this.creditCard.number)[0].type
						: false;
				if (flag) {
					if (flag === "american-express") flag = "amex";
					if (flag === "diners-club") flag = "diners";
					this.creditCard.flag = flag;
				}

				this.setCreditCardFlagImage();
			},
			flipCard(status) {
				this.isCardFlipped = status;
			},
			focusInput(e) {
				this.isInputFocused = true;
				let targetRef = e.target.dataset.ref;
				let target = this.$refs[targetRef];
				this.focusElementStyle = {
					width: `${target.offsetWidth}px`,
					height: `${target.offsetHeight}px`,
					transform: `translateX(${target.offsetLeft}px) translateY(${target.offsetTop}px)`,
				};
			},
			blurInput() {
				setTimeout(() => {
					if (!this.isInputFocused) {
						this.focusElementStyle = null;
					}
				}, 150);
				this.isInputFocused = false;
			},
		},
		watch: {
			plan: {
				handler: function (val) {
					console.log(val);
					if (val) this.getClientSecret();
				},
				deep: true,
				immediate: true,
			},
		},
	};
</script>
<style lang="scss" scoped>
	.payment-method-holder {
		position: relative;
		width: 100%;
		max-width: 570px;
		margin: 0 auto 30px auto;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-wrap: wrap;
		gap: 1rem;
		.title {
			position: relative;
			width: 100%;
		}
		.item {
			position: relative;
			padding: 10px 18px;
			display: flex;
			justify-content: center;
			align-items: center;
			border: 1px solid #ccc;
			cursor: pointer;
			border-radius: 8px;
			transition: border-color 0.3s ease;
			span {
				font-size: 1.15em;
				color: #aaa;
				transition: color 0.3s ease;
			}

			&:hover {
				border-color: #ccc;
				color: #aaa;
			}
			&.active {
				color: var(--secondary);
				border-color: var(--primary);
				span {
					color: var(--secondary);
				}
			}
		}
	}

	.ticket-holder {
		.observation {
			position: relative;
		}
	}

	.billing-title {
		margin-top: 35px;
		font-family: fontMedium;
		font-size: 1.15rem;
		color: var(--primary);
	}

	.payment-steps {
		position: relative;
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 35px auto 5px auto;
		pointer-events: none;
		&::before,
		&::after {
			position: absolute;
			content: "";
			width: 100%;
			height: 1px;
			background: #ddd;
			z-index: 1;
			transition: width 1.25s ease-out;
		}
		&::after {
			width: 0;
			background: var(--primary);
		}
		&.active::after {
			width: 100%;
		}
		.item {
			position: relative;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 0.5rem;
			background-color: #fff;
			padding: 0 15px;
			color: #aaa;
			transition: color 0.4s ease;
			transition-delay: 0.6s;
			z-index: 2;
			&::before {
				position: relative;
				content: "";
				width: 18px;
				height: 18px;
				border-radius: 100%;
				background-color: #ccc;
				transition: background-color 0.4s ease;
				transition-delay: 0.6s;
			}
			&:first-child {
				padding: 0 15px 0 0;
			}
			&:last-child {
				padding: 0 0 0 15px;
			}
			&.active {
				color: var(--primary);
				&::before {
					background-color: var(--primary);
				}
			}
		}
	}

	.user-data-holder {
		position: relative;
		width: 100%;
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
		gap: 0 20px;
		max-width: 570px;
		margin: 25px auto 0 auto;
	}

	.cards-label {
		position: relative;
		gap: 0.5rem;
		span {
			position: relative;
			color: #999;
		}
		img {
			max-width: 30px;
		}
	}

	.wrapper {
		display: flex;
		@media screen and (max-width: 700px), (max-height: 500px) {
			flex-wrap: wrap;
			flex-direction: column;
		}
	}

	.card-form {
		max-width: 570px;
		width: 100%;
		margin: 0 auto;

		@media screen and (max-width: 576px) {
			margin: 0 auto;
		}

		&__inner {
			padding-top: 30px;

			@media screen and (max-width: 480px) {
				padding-top: 25px;
			}
			@media screen and (max-width: 360px) {
				padding-top: 25px;
			}
		}

		&__row {
			display: flex;
			align-items: flex-start;
			@media screen and (max-width: 480px) {
				flex-wrap: wrap;
			}
		}

		&__col {
			flex: auto;
			margin-right: 35px;

			&:last-child {
				margin-right: 0;
			}

			@media screen and (max-width: 480px) {
				margin-right: 0;
				flex: unset;
				width: 100%;
				margin-bottom: 20px;

				&:last-child {
					margin-bottom: 0;
				}
			}

			&.-cvc {
				max-width: 150px;
				@media screen and (max-width: 480px) {
					max-width: initial;
				}
			}
		}

		&__group {
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-wrap: wrap;

			select.small {
				max-width: 46%;
			}
		}

		&__button {
			width: 100%;
			height: 55px;
			background: var(--secondary);
			border: none;
			border-radius: 5px;
			font-size: 22px;
			font-weight: 500;
			font-family: "Source Sans Pro", sans-serif;
			box-shadow: 3px 10px 20px 0px rgba(35, 100, 210, 0.3);
			color: #fff;
			margin-top: 20px;
			cursor: pointer;

			@media screen and (max-width: 480px) {
				margin-top: 10px;
			}
		}
	}

	.card-item {
		max-width: 430px;
		height: 250px;
		margin-left: auto;
		margin-right: auto;
		position: relative;
		z-index: 2;
		width: 100%;
		* {
			color: #fff;
		}

		@media screen and (max-width: 480px) {
			width: 100%;
		}

		&.-active {
			.card-item__side {
				&.-front {
					transform: perspective(1000px) rotateY(180deg) rotateX(0deg) rotateZ(0deg);
				}
				&.-back {
					transform: perspective(1000px) rotateY(0) rotateX(0deg) rotateZ(0deg);
					// box-shadow: 0 20px 50px 0 rgba(81, 88, 206, 0.65);
				}
			}
		}

		&__focus {
			position: absolute;
			z-index: 3;
			border-radius: 5px;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			transition: all 0.35s cubic-bezier(0.71, 0.03, 0.56, 0.85);
			opacity: 0;
			pointer-events: none;
			overflow: hidden;
			border: 2px solid rgba(255, 255, 255, 0.65);

			&:after {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				background: rgb(8, 20, 47);
				height: 100%;
				border-radius: 5px;
				filter: blur(25px);
				opacity: 0.5;
			}

			&.-active {
				opacity: 1;
			}
		}

		&__side {
			height: 100%;
			border-radius: 15px;
			overflow: hidden;
			box-shadow: 0 4px 10px 0 rgba(14, 42, 90, 0.35);
			transform: perspective(2000px) rotateY(0deg) rotateX(0deg) rotate(0deg);
			transform-style: preserve-3d;
			transition: all 0.8s cubic-bezier(0.71, 0.03, 0.56, 0.85);
			backface-visibility: hidden;

			&.-back {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				transform: perspective(2000px) rotateY(-180deg) rotateX(0deg) rotate(0deg);
				z-index: 2;
				padding: 0;
				// background-color: #2364d2;
				// background-image: linear-gradient(
				//   43deg,
				//   #4158d0 0%,
				//   #8555c7 46%,
				//   #2364d2 100%
				// );
				height: 100%;

				.card-item__cover {
					transform: rotateY(-180deg);
				}
			}
		}
		&__bg {
			max-width: 100%;
			display: block;
			max-height: 100%;
			height: 100%;
			width: 100%;
			object-fit: cover;
			opacity: 0.6;
		}
		&__cover {
			height: 100%;
			background-color: #1c1d27;
			position: absolute;
			height: 100%;
			background-color: #1c1d27;
			left: 0;
			top: 0;
			width: 100%;
			border-radius: 15px;
			overflow: hidden;
			&:after {
				content: "";
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				background: rgba(6, 2, 29, 0.45);
			}
		}

		&__top {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			margin-bottom: 30px;
			padding: 0 10px;

			@media screen and (max-width: 480px) {
				margin-bottom: 25px;
			}
			@media screen and (max-width: 360px) {
				margin-bottom: 15px;
			}
		}

		&__chip {
			width: 60px;
			@media screen and (max-width: 480px) {
				width: 50px;
			}
			@media screen and (max-width: 360px) {
				width: 40px;
			}
		}

		&__type {
			height: 45px;
			position: relative;
			display: flex;
			justify-content: flex-end;
			max-width: 100px;
			margin-left: auto;
			width: 100%;

			@media screen and (max-width: 480px) {
				height: 40px;
				max-width: 90px;
			}
			@media screen and (max-width: 360px) {
				height: 30px;
			}
		}

		&__typeImg {
			max-width: 100%;
			object-fit: contain;
			max-height: 100%;
			object-position: top right;
		}

		&__info {
			color: #fff;
			width: 100%;
			max-width: calc(100% - 85px);
			padding: 10px 15px;
			font-weight: 500;
			display: block;

			cursor: pointer;

			@media screen and (max-width: 480px) {
				padding: 10px;
			}
		}

		&__holder {
			opacity: 0.7;
			font-size: 13px;
			margin-bottom: 6px;
			@media screen and (max-width: 480px) {
				font-size: 12px;
				margin-bottom: 5px;
			}
		}

		&__wrapper {
			font-family: "Source Code Pro", monospace;
			padding: 25px 15px;
			position: relative;
			z-index: 4;
			height: 100%;
			text-shadow: 7px 6px 10px rgba(14, 42, 90, 0.8);
			user-select: none;
			@media screen and (max-width: 480px) {
				padding: 20px 10px;
			}
		}

		&__name {
			min-height: 21px;
			line-height: 1;
			white-space: nowrap;
			max-width: 100%;
			overflow: hidden;
			text-overflow: ellipsis;
			text-transform: uppercase;
			@media screen and (max-width: 480px) {
				font-size: 16px;
			}
		}
		&__nameItem {
			display: inline-block;
			min-width: 8px;
			position: relative;
			text-align: center;
			font-size: 1.2em;
		}

		&__number {
			font-weight: 500;
			line-height: 1;
			color: #fff;
			margin-bottom: 15px;
			display: inline-block;
			padding: 10px 15px;
			cursor: pointer;
			span * {
				font-size: 1.2em;
			}

			@media screen and (max-width: 480px) {
				margin-bottom: 15px;
				padding: 10px 10px;
			}

			@media screen and (max-width: 360px) {
				margin-bottom: 10px;
				padding: 10px 10px;
			}
		}

		&__numberItem {
			display: inline-block;
			&.-active {
				width: 30px;
			}

			@media screen and (max-width: 480px) {
				width: 13px;

				&.-active {
					width: 16px;
				}
			}

			@media screen and (max-width: 360px) {
				width: 12px;

				&.-active {
					width: 8px;
				}
			}
		}

		&__content {
			color: #fff;
			display: flex;
			align-items: flex-start;
		}

		&__date {
			flex-wrap: wrap;
			margin-left: auto;
			padding: 10px;
			display: inline-flex;
			width: 80px;
			white-space: nowrap;
			flex-shrink: 0;
			cursor: pointer;

			@media screen and (max-width: 480px) {
				font-size: 16px;
			}
		}

		&__dateItem {
			position: relative;
			margin-left: 4px;
			span {
				width: 22px;
				display: inline-block;
			}
		}

		&__dateTitle {
			opacity: 0.7;
			font-size: 13px;
			padding-bottom: 6px;
			width: 100%;

			@media screen and (max-width: 480px) {
				font-size: 12px;
				padding-bottom: 5px;
			}
		}
		&__band {
			background: rgba(0, 0, 19, 0.8);
			width: 100%;
			height: 50px;
			margin-top: 30px;
			position: relative;
			z-index: 2;
			@media screen and (max-width: 480px) {
				margin-top: 20px;
			}
			@media screen and (max-width: 360px) {
				height: 40px;
				margin-top: 10px;
			}
		}

		&__cvv {
			text-align: right;
			position: relative;
			z-index: 2;
			padding: 15px;
			.card-item__type {
				opacity: 0.7;
			}

			@media screen and (max-width: 360px) {
				padding: 10px 15px;
			}
		}
		&__cvvTitle {
			padding-right: 10px;
			font-size: 15px;
			font-weight: 500;
			color: #fff;
			margin-bottom: 5px;
		}
		&__cvvBand {
			height: 45px;
			background: #fff;
			margin-bottom: 30px;
			text-align: right;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			padding-right: 10px;
			color: #1a3b5d;
			font-size: 18px;
			border-radius: 4px;
			box-shadow: 0px 10px 20px -7px rgba(32, 56, 117, 0.35);

			@media screen and (max-width: 480px) {
				height: 40px;
				margin-bottom: 20px;
			}

			@media screen and (max-width: 360px) {
				margin-bottom: 15px;
			}
		}
	}

	.slide-fade-up-enter-active {
		transition: all 0.25s ease-in-out;
		transition-delay: 0.1s;
		position: relative;
	}
	.slide-fade-up-leave-active {
		transition: all 0.25s ease-in-out;
		position: absolute;
	}
	.slide-fade-up-enter {
		opacity: 0;
		transform: translateY(15px);
		pointer-events: none;
	}
	.slide-fade-up-leave-to {
		opacity: 0;
		transform: translateY(-15px);
		pointer-events: none;
	}

	.slide-fade-right-enter-active {
		transition: all 0.25s ease-in-out;
		transition-delay: 0.1s;
		position: relative;
	}
	.slide-fade-right-leave-active {
		transition: all 0.25s ease-in-out;
		position: absolute;
	}
	.slide-fade-right-enter {
		opacity: 0;
		transform: translateX(10px) rotate(45deg);
		pointer-events: none;
	}
	.slide-fade-right-leave-to {
		opacity: 0;
		transform: translateX(-10px) rotate(45deg);
		pointer-events: none;
	}
</style>
