<template>
  <div>
    <div class="plan-header">
      <h1>Máquina de conteúdo</h1>
      <span>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eget
        ligula eu lectus lobortis condimentum. Aliquam nonummy auctor massa.
        Pellentesque habitant morbi tristique senectus et netus et malesuada
        fames ac turpis egestas. Nulla at risus. Quisque purus magna, auctor et,
        sagittis ac, posuere eu, lectus. Nam mattis, felis ut adipiscing
      </span>
    </div>
    <div class="plans">
      <div
        v-for="plan in plans"
        class="plan"
        :class="{ active: selectedPlan == plan }"
        :key="plan.id"
      >
        <div class="header">
          <span>{{ plan.name }}</span>
        </div>
        <div class="body">
          <span class="price">{{ plan.price | currency }}</span>
          <span v-show="plan.intervalCount === 1">por mês</span>
          <span v-show="plan.intervalCount === 6">por semestre</span>
          <span v-show="plan.intervalCount === 12">por ano</span>
          <div
            class="discount"
            v-show="plan.intervalCount === 6 || plan.intervalCount === 12"
          >
            <span class="free" v-show="plan.intervalCount === 6"
              ><b>1 mês</b> grátis!</span
            >
            <span v-show="plan.intervalCount === 6"
              >Pague 5 meses e leve 6!</span
            >
            <span class="free" v-show="plan.intervalCount === 12"
              ><b>2 meses</b> grátis!</span
            >
            <span v-show="plan.intervalCount === 12"
              >Pague 10 meses e leve 12!</span
            >
          </div>
        </div>
        <div @click="setPlan(plan)" class="btn">
          <span>Continuar</span>
        </div>
      </div>
    </div>
    <div class="signup-buttons-holder">
      <div @click="prev" class="btn border prev">
        <span>Voltar</span>
      </div>
    </div>
  </div>
</template>
<script>
import http from "@/http";
export default {
  data() {
    return {
      plans: [],
      selectedPlan: null,
    };
  },
  created() {
    http.get("stripe/plan/list").then((response) => {
      this.plans = response.data;
    });
  },
  methods: {
    setPlan(plan) {
      this.selectedPlan = plan;
      this.validate();
    },
    prev() {
      this.$emit("prev");
    },
    validate() {
      if (!this.selectedPlan) {
        this.$swal({
          type: "error",
          title: "Plano *",
          text: "Por favor, escolha um plano.",
        });
        return;
      }

      this.$emit("next", this.selectedPlan);
    },
  },
};
</script>
<style lang="scss" scoped>
.plan-header {
  position: relative;
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 4rem auto 0 auto;
  text-align: center;
  gap: 2rem;
  h1 {
    font-size: 1.4rem;
  }
  span {
    font-family: fontLight;
    font-size: 1.1rem;
    line-height: 167.523%;
  }
}
.plans {
  position: relative;
  width: 70%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
  gap: 2rem;
  margin: 4rem auto 4rem auto;
}
.plan {
  position: relative;
  height: 325px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
  padding: 3rem 2rem;
  .header {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      text-align: center;
      font-size: 1.6rem;
    }
  }
  .body {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    span {
      font-family: fontLight;
    }
    .price {
      font-family: fontBlack;
      color: #1f9f2b;
      font-size: 1.6rem;
    }
    .discount {
      position: relative;
      width: 100%;
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 4px;
      .free {
        font-size: 1.6rem;
        color: #1782fd;
        font-family: fontRegular;
        b {
          font-size: 1.6rem;
          color: #1782fd;
        }
      }
    }
  }
}
</style>
