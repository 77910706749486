<template>
  <div class="user-data-holder">
    <div v-show="requireCPF" class="card-input">
      <span>CPF/CNPJ *</span>
      <the-mask
        v-model="user.taxId"
        :mask="['###.###.###-##', '##.###.###/####-##']"
        :masked="true"
      />
    </div>
    <div class="card-input">
      <span>E-mail *</span>
      <input type="text" v-model="user.email" />
    </div>

    <div class="card-input">
      <span>Telefone/celular *</span>
      <the-mask
        v-model="user.phoneNumber"
        :mask="['(##) ####-####', '(##) #####-####']"
        :masked="false"
      />
    </div>
    <div class="card-input">
      <span>CEP *</span>
      <the-mask
        @input.native="getCep()"
        v-model="user.postalCode"
        :mask="['#####-###']"
        :masked="false"
      />
    </div>
    <div v-show="showFullAddress" class="card-input">
      <span>UF *</span>
      <input type="text" v-model="user.state" />
    </div>
    <div v-show="showFullAddress" class="card-input">
      <span>Cidade *</span>
      <input type="text" v-model="user.city" />
    </div>
    <div v-show="showFullAddress" class="card-input">
      <span>Bairro *</span>
      <input type="text" v-model="user.neighborhood" />
    </div>
    <div v-show="showFullAddress" class="card-input">
      <span>Endereço *</span>
      <input type="text" v-model="user.address" />
    </div>
    <div v-show="showFullAddress" class="card-input">
      <span>Número</span>
      <input type="text" v-model="user.number" />
    </div>
    <div v-show="showFullAddress" class="card-input">
      <span>Complemento</span>
      <input type="text" v-model="user.complement" />
    </div>
  </div>
</template>
<script>
import cep from "cep-promise";
export default {
  props: {
    requireCPF: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      user: {
        name: "",
        taxId: "",
        email: "",
        phoneNumber: "",
        cpf: "",
        country: "BR",
        state: "",
        postalCode: "",
        neighborhood: "",
        address: "",
        number: "",
        complement: "",
        city: "",
      },
      showFullAddress: false,
    };
  },
  watch: {
    user: {
      handler: function (userObj) {
        this.$emit("setUserBillingInfo", userObj);
      },
      deep: true,
    },
  },
  methods: {
    getCep() {
      if (this.user.postalCode.length !== 8) {
        return;
      }

      cep(this.user.postalCode)
        .then((response) => {
          this.user.state = response.state;
          this.user.city = response.city;
          this.user.address = response.street;
          this.user.neighborhood = response.neighborhood;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.showFullAddress = true;
        });
    },
  },
};
</script>
