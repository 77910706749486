<template>
	<div class="all">
		<transition
			enter-active-class="animated fadeIn"
			leave-active-class="animated fadeOut"
		>
			<div v-show="showFullLoading" class="full-loading">
				<div class="loading2"></div>
				<p class="mt-1 color-primary" style="font-size: 1.15em">
					{{ signMessage }}
				</p>
			</div>
		</transition>

		<div class="left">
			<div class="inside">
				<div class="container">
					<img class="logo" src="@/assets/images/logo.png" alt="mysm logo" />
					<h1 class="animated fadeInLeft" style="animation-delay: 200ms">
						{{ t("Já possui cadastro?") }}
					</h1>
					<router-link
						to="/login"
						class="btn animated zoomIn mt-2"
						style="animation-delay: 400ms"
					>
						<span>{{ t("Faça login aqui") }}</span>
					</router-link>
				</div>
			</div>
		</div>
		<div class="content">
			<header>
				<h1 class="animated fadeInRight" style="animation-delay: 300ms">
					{{ t("Cadastro") }}
				</h1>
			</header>

			<div class="container signup">
				<div
					class="steps-holder animated zoomIn"
					style="animation-delay: 500ms"
					:class="{ first: step == 1, second: step == 2, third: step == 3 }"
				>
					<div class="item" :class="{ active: step > 0 }">
						<font-awesome-icon :icon="['fas', 'id-card-alt']" />
						<span>{{ t("Perfil") }}</span>
					</div>
					<div class="item" :class="{ active: step > 1 }">
						<font-awesome-icon :icon="['fas', 'file']" />
						<span>{{ t("Plano") }}</span>
					</div>
					<div class="item" :class="{ active: step > 2 }">
						<font-awesome-icon :icon="['fas', 'credit-card']" />
						<span>{{ t("Pagto") }}</span>
					</div>
				</div>

				<transition enter-active-class="animated fadeInRight">
					<PersonalInfo @next="nextStep" v-show="step == 1" />
				</transition>

				<transition enter-active-class="animated fadeInRight">
					<Plans @prev="prevStep" @next="nextStep" v-show="step == 2" />
				</transition>

				<transition enter-active-class="animated fadeInRight">
					<StripePayment
						@setPaymentInfo="setPaymentInfo"
						:validatingTransaction="validatingTransaction"
						:plan="selectedPlan"
						v-if="step == 3"
					/>
					<!-- <Payment
            @prev="prevStep"
            @creditcard="sendFormSubscription"
            @ticket="sendFormSubscription"
            :userInfo="user"
            :ticketInfo="selectedPlan"
            v-show="step == 3"
          /> -->
				</transition>
			</div>
		</div>
	</div>
</template>
<script>
	import authentication from "../util/authentication";
	import StripePayment from "../components/signup/StripePayment.vue";
	import http from "@/http";
	import PersonalInfo from "../components/signup/PersonalInfo";
	import Plans from "../components/signup/Plans";
	import Payment from "../components/signup/Payment";
	import platform from "../util/platform";

	export default {
		components: {
			PersonalInfo,
			Plans,
			Payment,
			StripePayment,
		},
		data() {
			return {
				renew: false,
				showFullLoading: false,
				signMessage: "",
				user: {
					name: "",
					professionalName: "",
					email: "",
					phone: "",
					cpf: "",
					zipcode: "",
					state: "",
					city: "",
					neighborhood: "",
					address: "",
					number: "",
					complement: "",
					birthday: "",
					auxBirthday: "",
					gender: "",
					language: "",
					profession: {
						id: "",
						name: "",
					},
					password: "",
				},
				userCard: {
					name: "",
					email: "",
					phone: "",
					cpf: "",
					birthday: "",
					auxBirthday: "",
					address: "",
					number: "",
					neighborhood: "",
					zipcode: "",
					city: "",
					state: "",
				},
				subscription: {
					planId: "",
					coupon: "",
					paymentMethod: 0, // 0 = cc ... 1 = boleto
					customer: {
						name: "",
						taxId: "",
						email: "",
						phoneNumber: "",
						city: "",
						state: "",
						country: "",
						postalCode: "",
						addressLine1: "",
						addressLine2: "",
					},
					creditCard: {
						number: "",
						cvc: "",
						expMonth: "12",
						expYear: "28",
					},
					coupon: "",
				},
				selectedPlan: {},
				step: 1,
				validatingTransaction: false,
			};
		},
		created() {
			if (platform.isIos()) {
				this.$router.push("/login");
			}
			if (this.$route.query.renew === "y") {
				http
					.get("user/details", authentication.getHeaders())
					.then((response) => {
						this.renew = true;
						this.nextStep(response.data);
					})
					.catch((err) => {
						console.log(err);
					});
			}
		},
		methods: {
			setPaymentInfo(method, user, creditCard) {
				this.subscription.paymentMethod = method;
				this.subscription.customer = { ...this.subscription.customer, ...user };
				this.subscription.customer.name = creditCard.name.trim();
				if (method === 1)
					this.subscription.customer.name = authentication.getUser()?.info.name;
				this.subscription.customer.addressLine1 = user.address;
				if (user.number)
					this.subscription.customer.addressLine1 += `, ${user.number}`;
				if (user.complement)
					this.subscription.customer.addressLine1 += `, ${user.complement}`;
				if (user.neighborhood)
					this.subscription.customer.addressLine1 += ` - ${user.neighborhood}`;

				this.subscription.creditCard = {
					...this.subscription.creditCard,
					...creditCard,
				};
				this.subscription.creditCard.number = creditCard.number.replace(/ /g, "");
				this.subscription.creditCard.expMonth = creditCard.expMonth.toString();
				this.subscription.creditCard.expYear = creditCard.expYear
					.toString()
					.substring(2, 4);

				if (this.renew) this.createSubscription();
				else this.createUser();
			},
			createSubscription() {
				this.validatingTransaction = true;
				this.showFullLoading = true;
				this.signMessage =
					this.$translate.locale[
						"Finalizando pagamento. Por favor, não feche nem recarregue a página."
					];
				http
					.post(
						"stripe/subscription",
						this.subscription,
						authentication.getHeaders()
					)
					.then((response) => {
						if (response.status === 200) {
							if (localStorage.user) {
								let user;
								user = JSON.parse(localStorage.user);
								user.info.subscription = response.data;
								localStorage.user = JSON.stringify(user);
							}

							this.successCreditCard();
						}
					})
					.catch((error) => {
						this.errorCreditCard();
					})
					.finally(() => {
						this.showFullLoading = false;
						this.validatingTransaction = false;
					});
			},
			createUser() {
				this.showFullLoading = true;
				this.signMessage = `${this.$translate.locale["Finalizando cadastro"]}...`;
				let splitDate = this.user.auxBirthday.split("/");
				this.user.birthday = `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`;
				http
					.post("user", this.user)
					.then((response) => {
						localStorage.user = JSON.stringify(response.data);
						this.showFullLoading = false;
						this.$swal
							.fire({
								type: "success",
								title: this.$translate.locale["Cadastro realizado com sucesso."],
								allowOutsideClick: false,
							})
							.then((res) => {
								if (res) {
									this.createSubscription();
								}
							});
					})
					.catch((err) => {
						console.log(err);
						this.$swal.fire({
							type: "error",
							title: this.$translate.locale["Erro no cadastro"],
							text:
								this.$translate.locale[
									"Algo deu errado com o seu cadastro. Por favor, verifique sua conexão e tente novamente em alguns instantes."
								],
						});

						this.showFullLoading = false;
					});
			},
			sendFormSubscription(
				useUserData,
				creditCardUserInfo,
				creditcard,
				paymentMethod
			) {
				var vm = this;
				if (this.renew) {
					this.continueToPayment(
						this.user,
						useUserData,
						creditCardUserInfo,
						creditcard,
						paymentMethod
					);

					return;
				}
				this.createUser();
			},
			prevStep() {
				if (this.step > 1) this.step--;
			},
			nextStep(obj) {
				if (this.step == 1) {
					this.user = { ...this.user, ...obj };
				}
				if (this.step == 2) {
					this.selectedPlan = obj;
					this.subscription.planId = this.selectedPlan?.id;
				}
				if (this.step < 3) this.step++;
			},
			continueToPayment(
				user,
				useUserData,
				creditCardUserInfo,
				creditcard,
				paymentMethod
			) {
				this.showFullLoading = true;
				this.signMessage =
					this.$translate.locale[
						"Finalizando pagamento. Por favor, não feche nem recarregue a página."
					];
				if (paymentMethod === "creditcard") {
					this.createSubscriptionByCreditCard(
						user,
						useUserData,
						creditCardUserInfo,
						creditcard
					);
				} else {
					this.createSubscriptionByBillet(user);
				}
			},
			successCreditCard() {
				var vm = this;
				vm.$swal
					.fire({
						type: "success",
						title: this.$translate.locale["Recebemos sua transação."],
						text:
							this.$translate.locale[
								"A confirmação do pagamento pode levar até 48 horas."
							],
					})
					.then((res) => {
						if (res) {
							vm.$router.push("/home?newUser=true");
						}
					});
			},
			errorCreditCard() {
				var vm = this;
				vm.$swal
					.fire({
						type: "error",
						title: this.$translate.locale["Erro no pagamento"],
						allowOutsideClick: false,
						text:
							this.$translate.locale[
								"Algo deu errado com o seu pagamento. Por favor, verifique todos os dados e tente novamente."
							],
					})
					.then((res) => {
						if (res) {
							localStorage.paymentFailed = true;
							vm.$router.push("/home?newUser=true");
						}
					});
			},
			errorValidateCreditCard() {
				var vm = this;
				vm.$swal
					.fire({
						type: "error",
						title: this.$translate.locale["Erro no pagamento"],
						allowOutsideClick: false,
						text:
							this.$translate.locale[
								"Algo deu errado com a validação do seu cartão. Por favor, verifique todos os dados e tente novamente."
							],
					})
					.then((res) => {
						if (res) {
							localStorage.paymentFailed = true;
							vm.$router.push("/home?newUser=true");
						}
					});

				this.showFullLoading = false;
			},
			createSubscriptionByCreditCard(
				user,
				useUserData,
				creditCardUserInfo,
				creditcard
			) {
				var vm = this;
				var cc = creditcard;
				consulta.getPaymentToken(
					{
						brand: cc.flag, // bandeira do cartão
						number: cc.number, // número do cartão
						cvv: cc.securityCode, // código de segurança
						expiration_month: cc.month, // mês de vencimento
						expiration_year: cc.year, // ano de vencimento
						sandbox: true,
					},
					function (error, response) {
						if (error) {
							this.errorValidateCreditCard();
						} else {
							var payment_token = response.data.payment_token;
							var subscriptionInfo = {
								PaymentMethod: "Gerencianet",
								PlanId: vm.selectedPlan.id,
								PaymentToken: payment_token,
								Coupon: null,
							};

							var subscriptionForm = {
								subscription: subscriptionInfo,
								user: user,
							};

							if (!useUserData) {
								subscriptionForm.user = creditCardUserInfo;
							}

							subscriptionForm.user.name = creditcard.name;
							http
								.post("Subscription", subscriptionForm, authentication.getHeaders())
								.then(() => {
									this.successCreditCard();
								})
								.catch((err) => {
									console.log(err);
									this.errorCreditCard();
								})
								.finally(() => {
									this.showFullLoading = false;
								});
						}
					}
				);
			},
			createSubscriptionByBillet(user) {
				var vm = this;
				var subscriptionInfo = {
					PaymentMethod: "GerencianetBoleto",
					PlanId: vm.selectedPlan.id,
					PaymentToken: "",
					Coupon: null,
				};

				var subscriptionForm = {
					subscription: subscriptionInfo,
					user: user,
				};
				http
					.post("Subscription", subscriptionForm, authentication.getHeaders())
					.then((res) => {
						vm.$swal
							.fire({
								type: "success",
								title: this.$translate.locale["Recebemos seu pedido."],
								allowOutsideClick: false,
								text: `${this.$translate.locale["Você recebrá o boleto no e-mail"]} ${user.email} ${this.$translate.locale["em até 48 horas"]}.`,
							})
							.then((res) => {
								if (res) {
									vm.$router.push("/home?newUser=true");
								}
							});
					})
					.catch((err) => {
						vm.$swal
							.fire({
								type: "error",
								title: this.$translate.locale["Erro no pagamento"],
								text:
									this.$translate.locale[
										"Algo deu errado com o seu pagamento. Por favor, verifique todos os dados e tente novamente."
									],
							})
							.then((res) => {
								if (res) {
									localStorage.paymentFailed = true;
									vm.$router.push("/home?newUser=true");
								}
							});
					})
					.finally(() => {
						this.showFullLoading = false;
					});
			},
		},
	};
</script>
<style lang="scss" scoped>
	.all {
		position: relative;
		width: 100vw;
		display: grid;
		grid-template-columns: 550px 1fr;
		@media screen and (max-width: 1139px) {
			grid-template-columns: 1fr;
		}
		@media screen and (min-width: 1140px) and (max-width: 1360px) {
			grid-template-columns: 390px 1fr;
		}
	}

	.left {
		position: relative;
		width: 550px;
		height: 100vh;
		@media screen and (min-width: 1140px) and (max-width: 1360px) {
			width: 390px;
		}
		@media screen and (max-width: 1139px) {
			display: none;
		}
		.inside {
			position: fixed;
			width: 550px;
			height: 100vh;
			top: 0;
			left: 0;
			background-color: var(--secondary);
			background-image: url("../assets/images/bg1.jpg");
			background-position: 51% center;
			background-size: cover;
			background-blend-mode: overlay;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			@media screen and (min-width: 1140px) and (max-width: 1360px) {
				width: 390px;
			}
			.container {
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				img.logo {
					position: absolute;
					top: 100px;
				}
			}
			h1 {
				position: relative;
				font-family: fontLight;
				font-size: 1.6rem;
				letter-spacing: 1px;
				color: #fff;
			}
			.login-link {
				position: relative;
				display: inline-block;
				margin-top: 35px;
				font-size: 1.3em;
				color: var(--primary);
				border-bottom: 1px solid var(--primary);
			}
		}
	}

	header {
		position: relative;
		width: 100%;
		padding: 0 5%;
		height: 90px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		h1 {
			font-size: 1.6rem;
			color: var(--secondary);
		}
	}

	.container.signup {
		max-width: initial;
		width: 90%;
		margin-bottom: 35px;
	}

	.steps-holder {
		position: relative;
		width: 100%;
		height: 48px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 15px;
		margin-bottom: 25px;
		.item {
			position: relative;
			background-color: #fff;
			z-index: 1;
			&:first-child {
				padding-right: 10px;
			}
			&:nth-child(2) {
				padding: 0 10px;
			}
			&:nth-child(3) {
				padding-left: 10px;
			}
			span {
				font-size: 1.1em;
				color: #ccc;
			}
			svg {
				font-size: 1.3em;
				margin-right: 8px;
				color: #ccc;
			}
			&.active {
				span,
				svg {
					color: var(--secondary);
				}
			}
		}
		&::before {
			position: absolute;
			content: "";
			width: 100%;
			height: 1px;
			background-color: #ccc;
		}
		&::after {
			position: absolute;
			content: "";
			width: 0;
			height: 1px;
			background-color: var(--secondary);
			transition: width 0.25s ease;
		}

		&.second {
			&::after {
				width: 50%;
			}
		}
		&.third {
			&::after {
				width: 100%;
			}
		}
	}
</style>
