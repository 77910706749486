<template>
  <div>
    <div class="inputs-holder">
      <div class="input-item">
        <span>{{ t("Nome completo") }} *</span>
        <input type="text" v-model="user.name" />
      </div>
      <div class="input-item">
        <span>E-mail *</span>
        <input type="text" v-model="user.email" />
      </div>
      <div class="input-item">
        <span>{{ t("Telefone/celular") }} *</span>
        <the-mask
          v-model="user.phone"
          :mask="['(##) ####-####', '(##) #####-####']"
          :masked="false"
        />
      </div>
      <div class="input-item">
        <span>{{ t("CPF") }} *</span>
        <the-mask
          v-model="user.cpf"
          :mask="['###.###.###-##', '##.###.###/####-##']"
          :masked="false"
        />
      </div>
      <div class="input-item">
        <span>{{ t("Data de nascimento") }} *</span>
        <the-mask
          v-model="user.auxBirthday"
          placeholder="DD/MM/AAAA"
          :mask="['##/##/####']"
          :masked="true"
        />
      </div>
      <div class="input-item">
        <span>{{ t("Sexo") }} *</span>
        <select class="appearance" v-model="user.gender">
          <option value="female">{{ t("Feminino") }}</option>
          <option value="male">{{ t("Masculino") }}</option>
        </select>
      </div>
      <div class="input-item">
        <span>{{ t("Profissão") }} *</span>
        <select type="text" v-model="user.profession">
          <option
            v-for="profession in professions"
            :key="profession.id"
            :value="profession"
          >
            {{ profession.name }}
          </option>
        </select>
      </div>
      <div class="input-item">
        <span>{{ t("CEP") }} *</span>
        <the-mask
          @blur.native="getCep()"
          v-model="user.zipcode"
          :mask="['#####-###']"
          :masked="false"
        />
      </div>
      <div v-show="showFullAddress" class="input-item">
        <span>{{ t("UF") }} *</span>
        <input type="text" v-model="user.state" />
      </div>
      <div v-show="showFullAddress" class="input-item">
        <span>{{ t("Cidade") }} *</span>
        <input type="text" v-model="user.city" />
      </div>
      <div v-show="showFullAddress" class="input-item">
        <span>{{ t("Bairro") }} *</span>
        <input type="text" v-model="user.neighborhood" />
      </div>
      <div v-show="showFullAddress" class="input-item">
        <span>{{ t("Endereço") }} *</span>
        <input type="text" v-model="user.address" />
      </div>
      <div v-show="showFullAddress" class="input-item">
        <span>{{ t("Complemento") }}</span>
        <input type="text" v-model="user.complement" />
      </div>
      <div v-show="showFullAddress" class="input-item">
        <span>{{ t("Número") }} *</span>
        <input type="text" v-model="user.number" />
      </div>
      <div v-show="showFullAddress" class="input-item">
        <span>{{ t("Senha") }} *</span>
        <input type="password" v-model="user.password" />
      </div>
      <div v-show="showFullAddress" class="input-item">
        <span>{{ t("Confirmar senha") }} *</span>
        <input type="password" v-model="password2" />
      </div>
    </div>

    <div class="signup-buttons-holder">
      <div @click="validate" class="btn border next">
        <span>{{ t("Avançar") }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import http from "@/http";
import validators from "@/util/validators";
import cep from "cep-promise";
export default {
  data() {
    return {
      user: {
        name: "",
        email: "",
        phone: "",
        cpf: "",
        zipcode: "",
        state: "",
        city: "",
        neighborhood: "",
        address: "",
        number: "",
        birthday: "",
        auxBirthday: "",
        gender: "",
        password: "",
        complement: "",
        profession: {
          id: "",
          name: "",
        },
        language: "Português",
      },
      password2: "",
      professions: [],
      showFullAddress: false,
    };
  },
  beforeCreate() {
    http.get("profession?take=-1").then((response) => {
      this.professions = response?.data?.list;
    });
  },
  methods: {
    getCep() {
      if (this.user.zipcode.length != 8) {
        this.$swal({
          type: "error",
          title: this.$translate.locale["CEP"] + " *",
          text: this.$translate.locale["Por favor, informe um CEP válido"],
        });
        return;
      }

      cep(this.user.zipcode)
        .then((response) => {
          this.user.state = response.state;
          this.user.city = response.city;
          this.user.address = response.street;
          this.user.neighborhood = response.neighborhood;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.showFullAddress = true;
        });
    },
    validate() {
      alert("Em breve");
      return;
      this.user.name = this.user.name.trim();
      this.user.email = this.user.email.trim();
      if (!validators.validateFullName(this.user.name)) {
        this.$swal({
          type: "error",
          title: this.$translate.locale["Nome completo"] + " *",
          text: this.$translate.locale[
            "Por favor, informe o seu nome completo"
          ],
        });
        return;
      }

      if (
        this.user.email == null ||
        !validators.validateEmail(this.user.email)
      ) {
        this.$swal({
          type: "error",
          title: "E-mail",
          text: this.$translate.locale["Por favor, informe um e-mail válido"],
        });
        return;
      }
      if (
        !validators.validateCPF(this.user.cpf) &&
        !validators.validateCNPJ(this.user.cpf)
      ) {
        this.$swal({
          type: "error",
          title: `${this.$translate.locale["CPF"]} *`,
          text: this.$translate.locale["Por favor, informe um cpf válido"],
        });
        return;
      }
      if (this.user.auxBirthday.length != 10) {
        this.$swal({
          type: "error",
          title: `${this.$translate.locale["Data de nascimento"]} *`,
          text: this.$translate.locale[
            "Por favor, informe uma data de nascimento válida"
          ],
        });
        return;
      }
      let phoneRegex = /^[1-9]{2}9?[0-9]{8}$/;
      if (!phoneRegex.test(this.user.phone)) {
        this.$swal({
          type: "error",
          title: `${this.$translate.locale["Telefone/celular"]} *`,
          text: this.$translate.locale["Por favor, informe um número válido"],
        });
        return;
      }
      if (this.user.zipcode.length != 8) {
        this.$swal({
          type: "error",
          title: `${this.$translate.locale["CEP"]} *`,
          text: this.$translate.locale["Por favor, informe um CEP válido"],
        });
        return;
      }
      if (this.user.state == "" || this.user.state.length != 2) {
        this.$swal({
          type: "error",
          title: `${this.$translate.locale["UF"]} *`,
          text: this.$translate.locale["Por favor, informe um UF válido"],
        });
        return;
      }
      if (this.user.city == "") {
        this.$swal({
          type: "error",
          title: `${this.$translate.locale["Cidade"]} *`,
          text: this.$translate.locale["Por favor, informe uma cidade"],
        });
        return;
      }
      if (this.user.neighborhood == "") {
        this.$swal({
          type: "error",
          title: `${this.$translate.locale["Bairro"]} *`,
          text: this.$translate.locale["Por favor, informe o bairro"],
        });
        return;
      }
      if (this.user.address == "") {
        this.$swal({
          type: "error",
          title: `${this.$translate.locale["Endereço"]} *`,
          text: this.$translate.locale["Por favor, informe o seu endereço"],
        });
        return;
      }
      if (this.user.number == "") {
        this.$swal({
          type: "error",
          title: `${this.$translate.locale["Número"]} *`,
          text: this.$translate.locale["Por favor, informe o seu número"],
        });
        return;
      }
      if (this.user.gender == "") {
        this.$swal({
          type: "error",
          title: `${this.$translate.locale["Sexo"]} *`,
          text: this.$translate.locale["Por favor, informe o seu sexo"],
        });
        return;
      }
      if (!this.user.profession.id) {
        this.$swal({
          type: "error",
          title: `${this.$translate.locale["Profissão"]} *`,
          text: this.$translate.locale["Por favor, informe uma profissão"],
        });
        return;
      }
      if (this.user.language == "") {
        this.$swal({
          type: "error",
          title: `${this.$translate.locale["Idioma"]} *`,
          text: this.$translate.locale["Por favor, informe um idoma"],
        });
        return;
      }
      if (this.user.password == "") {
        this.$swal({
          type: "error",
          title: `${this.$translate.locale["Senha"]} *`,
          text: this.$translate.locale["Por favor, digite a sua senha"],
        });
        return;
      }
      if (this.password2 == "") {
        this.$swal({
          type: "error",
          title: `${this.$translate.locale["Confirmar senha"]} *`,
          text: this.$translate.locale["Por favor, confirme a sua senha"],
        });
        return;
      }
      if (this.user.password !== this.password2) {
        this.$swal({
          type: "error",
          title: `${this.$translate.locale["Confirmar senha"]} *`,
          text: this.$translate.locale[
            "Suas senhas são diferentes. Por favor, digite-as novamente"
          ],
        });
        return;
      }
      this.$emit("next", this.user);
    },
  },
};
</script>
<style lang="scss" scoped>
.inputs-holder {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px 20px;
}
.appearance {
  appearance: none;
}
</style>
