<template>
  <div>
    <div class="payment-method-holder">
      <div
        @click="paymentMethod = 'creditcard'"
        class="item"
        :class="{ active: paymentMethod == 'creditcard' }"
      >
        <font-awesome-icon :icon="['fas', 'credit-card']" />
        <span>{{ t("Cartão de crédito") }}</span>
      </div>
      <div
        @click="paymentMethod = 'ticket'"
        class="item"
        :class="{ active: paymentMethod == 'ticket' }"
      >
        <font-awesome-icon :icon="['fas', 'barcode']" />
        <span>{{ t("Boleto") }}</span>
      </div>
    </div>

    <transition enter-active-class="animated zoomIn">
      <div v-show="paymentMethod == 'creditcard'" class="holder">
        <div class="check-holder mt-2">
          <input id="userData" type="checkbox" v-model="useUserData" />
          <label for="userData">{{
            t(
              "Se você é o titular do cartão de crédito, deixe essa opção marcada. Caso o cartão de crédito pertença a outra pessoa, desmarque essa opção e preencha os campos a seguir com os dados pessoais do titular do cartão (e-mail e telefone pode manter o seu)"
            )
          }}</label>
        </div>

        <div class="inputs-holder">
          <transition enter-active-class="animated fadeInUp">
            <div v-show="!useUserData" class="user-data-holder">
              <div class="input-item">
                <span>{{ t("Nome completo") }} *</span>
                <input type="text" v-model="user.name" />
              </div>
              <div class="input-item">
                <span>E-mail *</span>
                <input type="text" v-model="user.email" />
              </div>
              <div class="input-item">
                <span>{{ t("Telefone/celular") }} *</span>
                <the-mask
                  v-model="user.phone"
                  :mask="['(##) ####-####', '(##) #####-####']"
                  :masked="false"
                />
              </div>
              <div class="input-item">
                <span>{{ t("CPF") }} *</span>
                <the-mask
                  v-model="user.cpf"
                  :mask="['###.###.###-##', '##.###.###/####-##']"
                  :masked="false"
                />
              </div>
              <div class="input-item">
                <span>{{ t("Data de nascimento") }} *</span>
                <the-mask
                  v-model="user.birthday"
                  placeholder="DD/MM/AAAA"
                  :mask="['##/##/####']"
                  :masked="true"
                />
              </div>
              <div class="input-item">
                <span>{{ t("CEP") }} *</span>
                <the-mask
                  @blur.native="getCep()"
                  v-model="user.zipcode"
                  :mask="['#####-###']"
                  :masked="false"
                />
              </div>
              <div v-show="showFullAddress" class="input-item">
                <span>{{ t("UF") }} *</span>
                <input type="text" v-model="user.state" />
              </div>
              <div v-show="showFullAddress" class="input-item">
                <span>{{ t("Cidade") }} *</span>
                <input type="text" v-model="user.city" />
              </div>
              <div v-show="showFullAddress" class="input-item">
                <span>{{ t("Bairro") }} *</span>
                <input type="text" v-model="user.neighborhood" />
              </div>
              <div v-show="showFullAddress" class="input-item">
                <span>{{ t("Endereço") }} *</span>
                <input type="text" v-model="user.address" />
              </div>
              <div v-show="showFullAddress" class="input-item">
                <span>{{ t("Número") }} *</span>
                <input type="text" v-model="user.number" />
              </div>
            </div>
          </transition>
          <div class="wrapper">
            <div class="card-form">
              <div class="card-list">
                <div class="card-item" :class="{ '-active': isCardFlipped }">
                  <div class="card-item__side -front">
                    <div
                      class="card-item__focus"
                      :class="{ '-active': focusElementStyle }"
                      :style="focusElementStyle"
                      ref="focusElement"
                    ></div>
                    <div class="card-item__cover">
                      <img
                        src="../../assets/images/creditcard.jpg"
                        class="card-item__bg"
                      />
                    </div>

                    <div class="card-item__wrapper">
                      <div class="card-item__top">
                        <img
                          src="../../assets/images/chip.png"
                          class="card-item__chip"
                        />
                        <div class="card-item__type">
                          <transition name="slide-fade-up">
                            <img
                              v-show="creditCardFlagImage"
                              :src="creditCardFlagImage"
                              alt="bandeira do cartão"
                              class="card-item__typeImg"
                            />
                          </transition>
                        </div>
                      </div>
                      <label
                        for="cardNumber"
                        class="card-item__number"
                        ref="cardNumber"
                      >
                        <template v-if="getCardType === 'amex'">
                          <span
                            v-for="(n, $index) in amexCardMask"
                            :key="$index"
                          >
                            <transition name="slide-fade-up">
                              <div
                                class="card-item__numberItem"
                                v-if="
                                  $index > 4 &&
                                  $index < 14 &&
                                  creditCard.number.length > $index &&
                                  n.trim() !== ''
                                "
                              >
                                *
                              </div>
                              <div
                                class="card-item__numberItem"
                                :class="{ '-active': n.trim() === '' }"
                                :key="$index"
                                v-else-if="creditCard.number.length > $index"
                              >
                                {{ creditCard.number[$index] }}
                              </div>
                              <div
                                class="card-item__numberItem"
                                :class="{ '-active': n.trim() === '' }"
                                v-else
                                :key="$index + 1"
                              >
                                {{ n }}
                              </div>
                            </transition>
                          </span>
                        </template>

                        <template v-else>
                          <span
                            v-for="(n, $index) in generalCardMask"
                            :key="$index"
                          >
                            <transition name="slide-fade-up">
                              <div
                                class="card-item__numberItem"
                                v-if="
                                  $index > 4 &&
                                  $index < 15 &&
                                  creditCard.number.length > $index &&
                                  n.trim() !== ''
                                "
                              >
                                *
                              </div>
                              <div
                                class="card-item__numberItem"
                                :class="{ '-active': n.trim() === '' }"
                                :key="$index"
                                v-else-if="creditCard.number.length > $index"
                              >
                                {{ creditCard.number[$index] }}
                              </div>
                              <div
                                class="card-item__numberItem"
                                :class="{ '-active': n.trim() === '' }"
                                v-else
                                :key="$index + 1"
                              >
                                {{ n }}
                              </div>
                            </transition>
                          </span>
                        </template>
                      </label>
                      <div class="card-item__content">
                        <label
                          for="cardName"
                          class="card-item__info"
                          ref="cardName"
                        >
                          <div class="card-item__holder">
                            {{ t("Nome impresso") }}
                          </div>
                          <transition name="slide-fade-up">
                            <div
                              class="card-item__name"
                              v-if="creditCard.name.length"
                              key="1"
                            >
                              <transition-group name="slide-fade-right">
                                <span
                                  class="card-item__nameItem"
                                  v-for="(n, $index) in creditCard.name.replace(
                                    /\s\s+/g,
                                    ' '
                                  )"
                                  v-show="$index === $index"
                                  :key="$index + 1"
                                  >{{ n }}</span
                                >
                              </transition-group>
                            </div>
                            <div class="card-item__name" v-else key="2"></div>
                          </transition>
                        </label>
                        <div class="card-item__date" ref="cardDate">
                          <label for="cardMonth" class="card-item__dateTitle">{{
                            t("Validade")
                          }}</label>
                          <label for="cardMonth" class="card-item__dateItem">
                            <transition name="slide-fade-up">
                              <span
                                v-if="creditCard.month"
                                :key="creditCard.month"
                                >{{ creditCard.month }}</span
                              >
                              <span v-else key="2">MM</span>
                            </transition>
                          </label>
                          /
                          <label for="cardYear" class="card-item__dateItem">
                            <transition name="slide-fade-up">
                              <span
                                v-if="creditCard.year"
                                :key="creditCard.year"
                                >{{ String(creditCard.year).slice(2, 4) }}</span
                              >
                              <span v-else key="2">AA</span>
                            </transition>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-item__side -back">
                    <div class="card-item__cover">
                      <img
                        src="../../assets/images/creditcard.jpg"
                        class="card-item__bg"
                      />
                    </div>
                    <div class="card-item__band"></div>
                    <div class="card-item__cvv">
                      <div class="card-item__cvvTitle">CVV</div>
                      <div class="card-item__cvvBand">
                        <span
                          style="color: var(--secondary)"
                          v-for="(n, $index) in creditCard.securityCode"
                          :key="$index"
                        >
                          {{ n }}
                        </span>
                      </div>
                      <div class="card-item__type">
                        <img
                          v-show="creditCardFlagImage"
                          :src="creditCardFlagImage"
                          class="card-item__typeImg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-form__inner">
                <div class="card-input">
                  <label for="cardNumber" class="card-input__label"
                    >{{ t("Número do cartão") }} *</label
                  >
                  <input
                    type="text"
                    id="cardNumber"
                    class="card-input__input"
                    v-mask="generateCardNumberMask"
                    v-model="creditCard.number"
                    v-on:focus="focusInput"
                    v-on:blur="blurInput"
                    @input="checkCardFlag"
                    data-ref="cardNumber"
                    autocomplete="off"
                  />
                </div>
                <div class="card-input">
                  <label for="cardName" class="card-input__label"
                    >{{ t("Nome impresso no cartão") }} *</label
                  >
                  <input
                    type="text"
                    id="cardName"
                    class="card-input__input"
                    v-model="creditCard.name"
                    v-on:focus="focusInput"
                    v-on:blur="blurInput"
                    data-ref="cardName"
                    autocomplete="off"
                  />
                </div>

                <div class="card-input">
                  <label class="card-input__label" for="cardFlag"
                    >{{ t("Bandeira do cartão") }} *</label
                  >
                  <select
                    class="card-input__input -select"
                    @change="setCreditCardFlagImage"
                    v-model="creditCard.flag"
                  >
                    <option value="mastercard">Mastercard</option>
                    <option value="visa">Visa</option>
                    <option value="amex">American Express</option>
                    <option value="diners">Diners</option>
                    <option value="elo">Elo</option>
                    <option value="hipercard">Hipercard</option>
                  </select>
                </div>

                <div class="card-form__row">
                  <div class="card-form__col">
                    <div class="card-form__group">
                      <label for="cardMonth" class="card-input__label"
                        >{{ t("Validade") }} *</label
                      >
                      <select
                        class="card-input__input -select"
                        id="cardMonth"
                        v-model="creditCard.month"
                        v-on:focus="focusInput"
                        v-on:blur="blurInput"
                        data-ref="cardDate"
                      >
                        <option value="" disabled selected>Mês</option>
                        <option
                          :value="n < 10 ? '0' + n : n"
                          v-for="n in 12"
                          :disabled="n < minCardMonth"
                          :key="n"
                        >
                          {{ n < 10 ? "0" + n : n }}
                        </option>
                      </select>
                      <select
                        class="card-input__input -select"
                        id="cardYear"
                        v-model="creditCard.year"
                        v-on:focus="focusInput"
                        v-on:blur="blurInput"
                        data-ref="cardDate"
                      >
                        <option value="" disabled selected>Ano</option>
                        <option
                          :value="$index + minCardYear"
                          v-for="(n, $index) in 12"
                          :key="n"
                        >
                          {{ $index + minCardYear }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="card-form__col -cvv">
                    <div class="card-input">
                      <label for="cardCvv" class="card-input__label"
                        >CVV *</label
                      >
                      <input
                        type="text"
                        class="card-input__input"
                        id="cardCvv"
                        v-mask="'####'"
                        maxlength="4"
                        v-model="creditCard.securityCode"
                        v-on:focus="flipCard(true)"
                        v-on:blur="flipCard(false)"
                        autocomplete="off"
                      />
                    </div>
                  </div>
                </div>

                <button
                  class="btn mx-auto mt-1 desktop6"
                  style="background: #091025"
                  @click="validate"
                >
                  <span style="color: #f0f0f0">{{ t("Cadastrar") }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <transition enter-active-class="animated zoomIn">
      <div v-show="paymentMethod == 'ticket'" class="holder ticket mt-2">
        <h1>{{ t("Resumo das informações") }}</h1>

        <div class="item">
          <span>{{ t("Nome") }}</span>
          <p>{{ userInfo.name }}</p>
        </div>
        <div class="item">
          <span>E-mail</span>
          <p>{{ userInfo.email }}</p>
        </div>
        <div class="item">
          <span>{{ t("Plano") }}</span>
          <p>{{ ticketInfo ? ticketInfo.name : "" }}</p>
        </div>
        <div class="item">
          <span>{{ t("Descrição do plano") }}</span>
          <p>{{ ticketInfo ? ticketInfo.description : "" }}</p>
        </div>
        <div class="item">
          <span>{{ t("Método de pagamento") }}</span>
          <p>{{ t("Boleto") }}</p>
        </div>

        <button
          class="btn mx-auto mt-2"
          style="background: #091025"
          @click="validateTicket"
        >
          <span style="color: #f0f0f0">{{
            t("Gerar boleto e cadastrar")
          }}</span>
        </button>
      </div>
    </transition>

    <div class="signup-buttons-holder">
      <div @click="prev" class="btn border prev">
        <span>{{ t("Voltar") }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import validators from "@/util/validators";
import creditCardType from "credit-card-type";
import cep from "cep-promise";
export default {
  props: {
    userInfo: {
      type: Object,
      default: {},
    },
    ticketInfo: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      user: {
        name: "",
        email: "",
        phone: "",
        cpf: "",
        birthday: "",
        auxBirthday: "",
        address: "",
        number: "",
        neighborhood: "",
        zipcode: "",
        city: "",
        state: "",
      },
      creditCard: {
        number: "",
        name: "",
        month: "",
        year: "",
        securityCode: "",
        flag: "",
      },
      showFullAddress: false,
      creditCardFlagImage: "",
      useUserData: true,
      paymentMethod: "creditcard",
      minCardYear: new Date().getFullYear(),
      generalCardMask: "#### #### #### ####",
      cardNumberTemp: "",
      isCardFlipped: false,
      focusElementStyle: null,
      isInputFocused: false,
    };
  },
  computed: {
    getCardType() {
      // caso precise alterar a máscara do número do cartão com base na bandeira ...
      // let number = this.creditCard.number;
      // let re = new RegExp("^4");
      // if (number.match(re) != null) return "visa";

      // re = new RegExp("^(34|37)");
      // if (number.match(re) != null) return "amex";

      // re = new RegExp("^5[1-5]");
      // if (number.match(re) != null) return "mastercard";

      // re = new RegExp("^6011");
      // if (number.match(re) != null) return "discover";

      // re = new RegExp("^9792");
      // if (number.match(re) != null) return "troy";

      return ""; // default type
    },
    generateCardNumberMask() {
      return this.generalCardMask;
    },
    minCardMonth() {
      if (this.creditCard.year === this.minCardYear)
        return new Date().getMonth() + 1;
      return 1;
    },
  },
  methods: {
    validateTicket() {
      this.$emit("creditcard", false, this.user, {}, this.paymentMethod);
    },
    validate() {
      this.user.name = this.user.name.trim();
      if (!this.useUserData && !validators.validateFullName(this.user.name)) {
        this.$swal({
          type: "error",
          title: `${this.$translate.locale["Nome completo"]} *`,
          text: this.$translate.locale[
            "Por favor, informe o seu nome completo"
          ],
        });
        return;
      }

      if (!this.useUserData && !validators.validateEmail(this.user.email)) {
        this.$swal({
          type: "error",
          title: "E-mail *",
          text: this.$translate.locale["Por favor, informe um e-mail válido"],
        });
        return;
      }
      if (
        !this.useUserData &&
        !validators.validateCPF(this.user.cpf) &&
        !validators.validateCNPJ(this.user.cpf)
      ) {
        this.$swal({
          type: "error",
          title: `${this.$translate.locale["CPF"]} *`,
          text: this.$translate.locale["Por favor, informe um cpf válido"],
        });
        return;
      }
      if (!this.useUserData && this.user.birthday.length != 10) {
        this.$swal({
          type: "error",
          title: `${this.$translate.locale["Data de nascimento"]} *`,
          text: this.$translate.locale[
            "Por favor, informe uma data de nascimento válida"
          ],
        });
        return;
      }
      let phoneRegex = /^[1-9]{2}9?[0-9]{8}$/;
      if (!this.useUserData && !phoneRegex.test(this.user.phone)) {
        this.$swal({
          type: "error",
          title: `${this.$translate.locale["Telefone/celular"]} *`,
          text: this.$translate.locale["Por favor, informe um número válido"],
        });
        return;
      }
      if (!this.useUserData && this.user.zipcode.length != 8) {
        this.$swal({
          type: "error",
          title: `${this.$translate.locale["CEP"]} *`,
          text: this.$translate.locale["Por favor, informe um CEP válido"],
        });
        return;
      }
      if (!this.useUserData && !this.user.state) {
        this.$swal({
          type: "error",
          title: `${this.$translate.locale["UF"]} *`,
          text: this.$translate.locale["Por favor, informe um UF válido"],
        });
        return;
      }
      if (!this.useUserData && this.user.city == "") {
        this.$swal({
          type: "error",
          title: `${this.$translate.locale["Cidade"]} *`,
          text: this.$translate.locale["Por favor, informe uma cidade"],
        });
        return;
      }
      if (!this.useUserData && this.user.neighborhood == "") {
        this.$swal({
          type: "error",
          title: `${this.$translate.locale["Bairro"]} *`,
          text: this.$translate.locale["Por favor, informe o bairro"],
        });
        return;
      }
      if (!this.useUserData && this.user.address == "") {
        this.$swal({
          type: "error",
          title: `${this.$translate.locale["Endereço"]} *`,
          text: this.$translate.locale["Por favor, informe o seu endereço"],
        });
        return;
      }
      if (!this.useUserData && this.user.number == "") {
        this.$swal({
          type: "error",
          title: `${this.$translate.locale["Número"]} *`,
          text: this.$translate.locale["Por favor, informe o seu número"],
        });
        return;
      }

      if (this.creditCard.number.length < 13) {
        this.$swal({
          type: "error",
          title: `${this.$translate.locale["Número do cartão"]} *`,
          text: this.$translate.locale[
            "Por favor, verifique o número do cartão informado"
          ],
        });
        return;
      }

      if (!this.creditCard.name) {
        this.$swal({
          type: "error",
          title: `${this.$translate.locale["Nome no cartão"]} *`,
          text: this.$translate.locale[
            "Por favor, informe o nome impresso no cartão"
          ],
        });
        return;
      }

      let flags = ["mastercard", "visa", "amex", "diners", "elo", "hipercard"];
      if (!flags.includes(this.creditCard.flag)) {
        this.$swal({
          type: "error",
          title: `${this.$translate.locale["Bandeira do cartão"]} *`,
          text: this.$translate.locale[
            "Por favor, informe a bandeira do cartão. Aceitamos Mastercard, Visa, Amex, Diners, Elo e Hipercard"
          ],
        });
        return;
      }

      this.$emit(
        "creditcard",
        this.useUserData,
        this.user,
        this.creditCard,
        this.paymentMethod
      );
    },
    getCep() {
      if (this.user.zipcode.length != 8) {
        this.$swal({
          type: "error",
          title: `${this.$translate.locale["CEP"]} *`,
          text: this.$translate.locale["Por favor, informe um CEP válido"],
        });
        return;
      }

      cep(this.user.zipcode)
        .then((response) => {
          this.user.state = response.state;
          this.user.city = response.city;
          this.user.address = response.street;
          this.user.neighborhood = response.neighborhood;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.showFullAddress = true;
        });
    },
    prev() {
      this.$emit("prev");
    },
    setCreditCardFlagImage() {
      if (this.creditCard.flag == "mastercard") {
        this.creditCardFlagImage = require("@/assets/images/mastercard.png");
      } else if (this.creditCard.flag == "visa") {
        this.creditCardFlagImage = require("@/assets/images/visa.png");
      } else if (this.creditCard.flag == "amex") {
        this.creditCardFlagImage = require("@/assets/images/amex.jpg");
      } else if (this.creditCard.flag == "diners") {
        this.creditCardFlagImage = require("@/assets/images/diners.png");
      } else if (this.creditCard.flag == "elo") {
        this.creditCardFlagImage = require("@/assets/images/elo.png");
      } else if (this.creditCard.flag == "hipercard") {
        this.creditCardFlagImage = require("@/assets/images/hipercard.png");
      } else {
        this.creditCardFlagImage = "";
      }
    },
    checkCardFlag() {
      let flag =
        creditCardType(this.creditCard.number) &&
        creditCardType(this.creditCard.number)[0]
          ? creditCardType(this.creditCard.number)[0].type
          : false;
      if (flag) {
        if (flag === "american-express") flag = "amex";
        if (flag === "diners-club") flag = "diners";
        this.creditCard.flag = flag;
      }

      this.setCreditCardFlagImage();
    },
    flipCard(status) {
      this.isCardFlipped = status;
    },
    focusInput(e) {
      this.isInputFocused = true;
      let targetRef = e.target.dataset.ref;
      let target = this.$refs[targetRef];
      this.focusElementStyle = {
        width: `${target.offsetWidth}px`,
        height: `${target.offsetHeight}px`,
        transform: `translateX(${target.offsetLeft}px) translateY(${target.offsetTop}px)`,
      };
    },
    blurInput() {
      let vm = this;
      setTimeout(() => {
        if (!vm.isInputFocused) {
          vm.focusElementStyle = null;
        }
      }, 300);
      vm.isInputFocused = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.payment-method-holder {
  position: relative;
  width: 100%;
  max-width: 570px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .item {
    position: relative;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
    &.active {
      border-color: var(--secondary);
      svg,
      span {
        color: var(--secondary);
      }
    }
    svg {
      font-size: 1.8em;
      margin-right: 12px;
      color: #aaa;
    }
    span {
      font-size: 1.15em;
      color: #aaa;
    }
  }
}

.holder.ticket {
  position: relative;
  width: 100%;
  max-width: 570px;
  margin: 0 auto;
  background: #fff;
  box-shadow: 0 30px 60px 0 rgba(90, 116, 148, 0.4);
  border-radius: 10px;
  padding: 35px;
  h1 {
    font-size: 1.8em;
  }
  .item {
    position: relative;
    width: 100%;
    margin-top: 20px;
    span {
      font-size: 1.2em;
    }
    p {
      font-size: 1.4em;
      margin-top: 6px;
      font-family: fontMedium;
    }
  }
}

.check-holder {
  position: relative;
  width: 100%;
  max-width: 570px;
  margin: 0 auto;
  label {
    font-size: 1.1em;
    color: var(--secondary);
    margin-left: 10px;
  }
}

.user-data-holder {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 0 20px;
  max-width: 570px;
  margin: 25px auto 0 auto;
}

.wrapper {
  display: flex;
  padding: 50px 15px;
  @media screen and (max-width: 700px), (max-height: 500px) {
    flex-wrap: wrap;
    flex-direction: column;
  }
}

.card-form {
  max-width: 570px;
  width: 100%;
  margin: 0 auto;

  @media screen and (max-width: 576px) {
    margin: 0 auto;
  }

  &__inner {
    background: #fff;
    // box-shadow: 3px 13px 30px 0px rgba(21, 34, 67, 0.2);
    box-shadow: 0 30px 60px 0 rgba(90, 116, 148, 0.4);
    border-radius: 10px;
    padding: 35px;
    padding-top: 180px;

    @media screen and (max-width: 480px) {
      padding: 25px;
      padding-top: 165px;
    }
    @media screen and (max-width: 360px) {
      padding: 15px;
      padding-top: 165px;
    }
  }

  &__row {
    display: flex;
    align-items: flex-start;
    @media screen and (max-width: 480px) {
      flex-wrap: wrap;
    }
  }

  &__col {
    flex: auto;
    margin-right: 35px;

    &:last-child {
      margin-right: 0;
    }

    @media screen and (max-width: 480px) {
      margin-right: 0;
      flex: unset;
      width: 100%;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &.-cvv {
      max-width: 150px;
      @media screen and (max-width: 480px) {
        max-width: initial;
      }
    }
  }

  &__group {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    .card-input__input {
      flex: 1;
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__button {
    width: 100%;
    height: 55px;
    background: var(--secondary);
    border: none;
    border-radius: 5px;
    font-size: 22px;
    font-weight: 500;
    font-family: "Source Sans Pro", sans-serif;
    box-shadow: 3px 10px 20px 0px rgba(35, 100, 210, 0.3);
    color: #fff;
    margin-top: 20px;
    cursor: pointer;

    @media screen and (max-width: 480px) {
      margin-top: 10px;
    }
  }
}

.card-item {
  max-width: 430px;
  height: 270px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 2;
  width: 100%;
  * {
    color: #fff;
  }

  @media screen and (max-width: 480px) {
    max-width: 310px;
    height: 220px;
    width: 90%;
  }

  @media screen and (max-width: 360px) {
    height: 180px;
  }

  &.-active {
    .card-item__side {
      &.-front {
        transform: perspective(1000px) rotateY(180deg) rotateX(0deg)
          rotateZ(0deg);
      }
      &.-back {
        transform: perspective(1000px) rotateY(0) rotateX(0deg) rotateZ(0deg);
        // box-shadow: 0 20px 50px 0 rgba(81, 88, 206, 0.65);
      }
    }
  }

  &__focus {
    position: absolute;
    z-index: 3;
    border-radius: 5px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: all 0.35s cubic-bezier(0.71, 0.03, 0.56, 0.85);
    opacity: 0;
    pointer-events: none;
    overflow: hidden;
    border: 2px solid rgba(255, 255, 255, 0.65);

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background: rgb(8, 20, 47);
      height: 100%;
      border-radius: 5px;
      filter: blur(25px);
      opacity: 0.5;
    }

    &.-active {
      opacity: 1;
    }
  }

  &__side {
    border-radius: 15px;
    overflow: hidden;
    // box-shadow: 3px 13px 30px 0px rgba(11, 19, 41, 0.5);
    box-shadow: 0 20px 60px 0 rgba(14, 42, 90, 0.55);
    transform: perspective(2000px) rotateY(0deg) rotateX(0deg) rotate(0deg);
    transform-style: preserve-3d;
    transition: all 0.8s cubic-bezier(0.71, 0.03, 0.56, 0.85);
    backface-visibility: hidden;
    height: 100%;

    &.-back {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      transform: perspective(2000px) rotateY(-180deg) rotateX(0deg) rotate(0deg);
      z-index: 2;
      padding: 0;
      // background-color: #2364d2;
      // background-image: linear-gradient(
      //   43deg,
      //   #4158d0 0%,
      //   #8555c7 46%,
      //   #2364d2 100%
      // );
      height: 100%;

      .card-item__cover {
        transform: rotateY(-180deg);
      }
    }
  }
  &__bg {
    max-width: 100%;
    display: block;
    max-height: 100%;
    height: 100%;
    width: 100%;
    object-fit: cover;
    opacity: 0.6;
  }
  &__cover {
    height: 100%;
    background-color: #1c1d27;
    position: absolute;
    height: 100%;
    background-color: #1c1d27;
    left: 0;
    top: 0;
    width: 100%;
    border-radius: 15px;
    overflow: hidden;
    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(6, 2, 29, 0.45);
    }
  }

  &__top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 40px;
    padding: 0 10px;

    @media screen and (max-width: 480px) {
      margin-bottom: 25px;
    }
    @media screen and (max-width: 360px) {
      margin-bottom: 15px;
    }
  }

  &__chip {
    width: 60px;
    @media screen and (max-width: 480px) {
      width: 50px;
    }
    @media screen and (max-width: 360px) {
      width: 40px;
    }
  }

  &__type {
    height: 45px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    max-width: 100px;
    margin-left: auto;
    width: 100%;

    @media screen and (max-width: 480px) {
      height: 40px;
      max-width: 90px;
    }
    @media screen and (max-width: 360px) {
      height: 30px;
    }
  }

  &__typeImg {
    max-width: 100%;
    object-fit: contain;
    max-height: 100%;
    object-position: top right;
  }

  &__info {
    color: #fff;
    width: 100%;
    max-width: calc(100% - 85px);
    padding: 10px 15px;
    font-weight: 500;
    display: block;

    cursor: pointer;

    @media screen and (max-width: 480px) {
      padding: 10px;
    }
  }

  &__holder {
    opacity: 0.7;
    font-size: 13px;
    margin-bottom: 6px;
    @media screen and (max-width: 480px) {
      font-size: 12px;
      margin-bottom: 5px;
    }
  }

  &__wrapper {
    font-family: "Source Code Pro", monospace;
    padding: 25px 15px;
    position: relative;
    z-index: 4;
    height: 100%;
    text-shadow: 7px 6px 10px rgba(14, 42, 90, 0.8);
    user-select: none;
    @media screen and (max-width: 480px) {
      padding: 20px 10px;
    }
  }

  &__name {
    min-height: 21px;
    line-height: 1;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
    @media screen and (max-width: 480px) {
      font-size: 16px;
    }
  }
  &__nameItem {
    display: inline-block;
    min-width: 8px;
    position: relative;
    text-align: center;
    font-size: 1.2em;
  }

  &__number {
    font-weight: 500;
    line-height: 1;
    color: #fff;
    margin-bottom: 35px;
    display: inline-block;
    padding: 10px 15px;
    cursor: pointer;
    span * {
      font-size: 1.2em;
    }

    @media screen and (max-width: 480px) {
      font-size: 21px;
      margin-bottom: 15px;
      padding: 10px 10px;
    }

    @media screen and (max-width: 360px) {
      font-size: 19px;
      margin-bottom: 10px;
      padding: 10px 10px;
    }
  }

  &__numberItem {
    width: 16px;
    display: inline-block;
    &.-active {
      width: 30px;
    }

    @media screen and (max-width: 480px) {
      width: 13px;

      &.-active {
        width: 16px;
      }
    }

    @media screen and (max-width: 360px) {
      width: 12px;

      &.-active {
        width: 8px;
      }
    }
  }

  &__content {
    color: #fff;
    display: flex;
    align-items: flex-start;
  }

  &__date {
    flex-wrap: wrap;
    margin-left: auto;
    padding: 10px;
    display: inline-flex;
    width: 80px;
    white-space: nowrap;
    flex-shrink: 0;
    cursor: pointer;

    @media screen and (max-width: 480px) {
      font-size: 16px;
    }
  }

  &__dateItem {
    position: relative;
    margin-left: 4px;
    span {
      width: 22px;
      display: inline-block;
    }
  }

  &__dateTitle {
    opacity: 0.7;
    font-size: 13px;
    padding-bottom: 6px;
    width: 100%;

    @media screen and (max-width: 480px) {
      font-size: 12px;
      padding-bottom: 5px;
    }
  }
  &__band {
    background: rgba(0, 0, 19, 0.8);
    width: 100%;
    height: 50px;
    margin-top: 30px;
    position: relative;
    z-index: 2;
    @media screen and (max-width: 480px) {
      margin-top: 20px;
    }
    @media screen and (max-width: 360px) {
      height: 40px;
      margin-top: 10px;
    }
  }

  &__cvv {
    text-align: right;
    position: relative;
    z-index: 2;
    padding: 15px;
    .card-item__type {
      opacity: 0.7;
    }

    @media screen and (max-width: 360px) {
      padding: 10px 15px;
    }
  }
  &__cvvTitle {
    padding-right: 10px;
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    margin-bottom: 5px;
  }
  &__cvvBand {
    height: 45px;
    background: #fff;
    margin-bottom: 30px;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 10px;
    color: #1a3b5d;
    font-size: 18px;
    border-radius: 4px;
    box-shadow: 0px 10px 20px -7px rgba(32, 56, 117, 0.35);

    @media screen and (max-width: 480px) {
      height: 40px;
      margin-bottom: 20px;
    }

    @media screen and (max-width: 360px) {
      margin-bottom: 15px;
    }
  }
}

.card-list {
  margin-bottom: -130px;

  @media screen and (max-width: 480px) {
    margin-bottom: -120px;
  }
}

.card-input {
  margin-bottom: 20px;
  &__label {
    font-size: 14px;
    margin-bottom: 5px;
    font-weight: 500;
    color: #1a3b5d;
    width: 100%;
    display: block;
    user-select: none;
  }
  &__input {
    width: 100%;
    height: 48px;
    border-radius: 5px;
    box-shadow: none;
    border: 1px solid #ced6e0;
    transition: all 0.3s ease-in-out;
    font-size: 18px;
    padding: 5px 15px;
    background: none;
    color: var;
    font-family: "Source Sans Pro", sans-serif;

    &:hover,
    &:focus {
      border-color: var(--primary);
    }

    &:focus {
      border-color: var(--primary);
      box-shadow: 0px 10px 20px -13px rgba(117, 73, 32, 0.35);
    }
    &.-select {
      background-size: 12px;
      background-position: 90% center;
      background-repeat: no-repeat;
      padding-right: 30px;
    }
  }
}

.slide-fade-up-enter-active {
  transition: all 0.25s ease-in-out;
  transition-delay: 0.1s;
  position: relative;
}
.slide-fade-up-leave-active {
  transition: all 0.25s ease-in-out;
  position: absolute;
}
.slide-fade-up-enter {
  opacity: 0;
  transform: translateY(15px);
  pointer-events: none;
}
.slide-fade-up-leave-to {
  opacity: 0;
  transform: translateY(-15px);
  pointer-events: none;
}

.slide-fade-right-enter-active {
  transition: all 0.25s ease-in-out;
  transition-delay: 0.1s;
  position: relative;
}
.slide-fade-right-leave-active {
  transition: all 0.25s ease-in-out;
  position: absolute;
}
.slide-fade-right-enter {
  opacity: 0;
  transform: translateX(10px) rotate(45deg);
  pointer-events: none;
}
.slide-fade-right-leave-to {
  opacity: 0;
  transform: translateX(-10px) rotate(45deg);
  pointer-events: none;
}
</style>
